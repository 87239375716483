@import url("https://fonts.googleapis.com/css2?family=VT323&display=swap");

:root {
  --primary-color: #356d05;
  --secondary-color: #616b57;
  --tertiary-color: #89a265;
  --quaternary-color: #dee2d2;
  --text-color: #333333;
  --bg-color: #ffffff;
  --card-bg: #f5f5f5;
}

body {
  font-family: "Courier New", monospace;
  line-height: 1.6;
  color: var(--text-color);
  background-color: var(--bg-color);
  margin: 0;
  padding: 0;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 2rem;
}

/* Header styles */
.App-header {
  background-color: var(--quaternary-color);
  padding: 1rem 0;
}

.App-nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.logo {
  font-size: 1.5rem;
  color: var(--primary-color);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.logo-text,
.footer-logo {
  font-family: "VT323", monospace;
  font-size: 2rem;
  font-weight: bold;
}

.logo-subtext {
  font-size: 0.8rem;
  color: var(--secondary-color);
}

.highlight {
  color: var(--primary-color);
}

.App-nav ul {
  list-style-type: none;
  display: flex;
  gap: 2rem;
}

@media screen and (max-width: 768px) {
  .App-nav ul {
    position: absolute;
    flex-direction: column;
    padding: 20px 0;
    top: -100%;
    right: 0;
    width: 100vw;
    background-color: #fff;
    border: 2px solid #89a265;
    border-left: none;
    border-right: none;
    opacity: 0;
    visibility: hidden;
    transition: top 0.5s ease-in-out, opacity 0.5s ease-in-out,
      visibility 0.5s ease-in-out;
  }

  .App-nav ul.active {
    top: 85px;
    opacity: 1;
    visibility: visible;
  }
}

.App-nav a {
  color: var(--secondary-color);
  text-decoration: none;
  font-weight: bold;
  transition: color 0.3s ease;
}

.App-nav a:hover {
  color: var(--primary-color);
}

/* Hero section styles */
.hero-section {
  text-align: center;
  padding: 4rem 0;
  background-color: var(--tertiary-color);
  color: var(--bg-color);
}

.main-title {
  font-family: "VT323", monospace;
  font-size: 4rem;
  color: var(--primary-color);
  margin-bottom: 1rem;
}

.subtitle {
  font-size: 1.5rem;
  color: var(--bg-color);
  margin-bottom: 1rem;
}

.hero-cta {
  display: flex;
  justify-content: center;
  gap: 1rem;
}

@media screen and (max-width: 768px) {
  .cta-container {
    display: flex;
    justify-content: center;
  }
}

.cta-button {
  display: inline-block;
  background-color: var(--primary-color);
  color: var(--bg-color);
  text-decoration: none;
  padding: 0.75rem 1.5rem;
  font-size: 1rem;
  font-weight: bold;
  border-radius: 5px;
  transition: all 0.3s ease;
}

.cta-button:hover {
  background-color: var(--secondary-color);
}

.cta-button.secondary {
  background-color: transparent;
  border: 2px solid var(--primary-color);
  color: var(--primary-color);
}

.cta-button.secondary:hover {
  background-color: var(--primary-color);
  color: var(--bg-color);
}

/* Section styles */
.section-title {
  font-size: 2rem;
  color: var(--primary-color);
  margin-bottom: 2rem;
  text-align: center;
}

.about-section,
.offer-section,
.join-section,
.events-section {
  padding: 4rem 0;
}

.about-content {
  display: flex;
  gap: 2rem;
  align-items: center;
}

.about-text {
  flex: 1;
}

.about-image {
  flex: 1;
}

.about-image img {
  max-width: 100%;
  border-radius: 10px;
  border: 2px solid var(--primary-color);
}

/* Offer section styles */
.offer-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 2rem;
}

.offer-item {
  background-color: var(--card-bg);
  padding: 2rem;
  border-radius: 10px;
  text-align: center;
  transition: transform 0.3s ease;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  position: relative;
  overflow: hidden;
}

.offer-item:hover {
  transform: translateY(-5px);
}

.offer-item::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
  transition: opacity 0.3s ease;
  opacity: 0.3;
}

.offer-item:hover::before {
  opacity: 0.5;
}

.offer-item-1::before {
  background-color: #a8e6cf;
}
.offer-item-2::before {
  background-color: #dcedc1;
}
.offer-item-3::before {
  background-color: #ffd3b6;
}
.offer-item-4::before {
  background-color: #ffaaa5;
}

.offer-item i {
  font-size: 3rem;
  color: var(--primary-color);
  margin-bottom: 1rem;
}

/* Events page styles */
.events-page {
  background-color: #f5f5f5;
}

.events-intro {
  background-color: var(--quaternary-color);
  padding: 4rem 0;
}

.events-description {
  font-size: 1.1rem;
  line-height: 1.6;
  color: var(--secondary-color);
  max-width: 800px;
  margin: 0 auto 2rem;
  text-align: center;
}

.events-features {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 2rem;
}

.feature {
  background-color: #ffffff;
  padding: 1.5rem;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.feature h3 {
  color: var(--primary-color);
  margin-bottom: 0.5rem;
}

.upcoming-events,
.past-events {
  padding: 4rem 0;
}

.events-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
  gap: 2rem;
}

.event-card {
  background-color: #ffffff;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  display: flex;
  transition: transform 0.3s ease;
}

.event-card:hover {
  transform: translateY(-5px);
}

.event-date {
  background-color: var(--primary-color);
  color: #ffffff;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-width: 80px;
}

.event-date .day {
  font-size: 1.5rem;
  font-weight: bold;
}

.event-date .month {
  text-transform: uppercase;
}

.event-details {
  padding: 1.5rem;
  flex: 1;
}

.event-details h3 {
  color: var(--primary-color);
  margin-bottom: 0.5rem;
}

.event-location {
  font-style: italic;
  color: var(--secondary-color);
  margin-bottom: 0.5rem;
}

.event-summary {
  margin-bottom: 1rem;
}

.event-actions {
  display: flex;
  gap: 1rem;
}

.event-link {
  display: inline-block;
  background-color: var(--primary-color);
  color: #ffffff;
  padding: 0.5rem 1rem;
  border-radius: 5px;
  text-decoration: none;
  font-size: 0.9rem;
  transition: background-color 0.3s ease;
  cursor: pointer;
  border: none;
}

.event-link:hover {
  background-color: var(--secondary-color);
}

.event-link.secondary {
  background-color: transparent;
  border: 1px solid var(--primary-color);
  color: var(--primary-color);
}

.event-link.secondary:hover {
  background-color: var(--primary-color);
  color: #ffffff;
}

/* Modal styles */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background-color: #ffffff;
  padding: 2rem;
  border-radius: 10px;
  max-width: 600px;
  width: 90%;
  max-height: 80vh;
  overflow-y: auto;
  position: relative;
}

.modal-content h2 {
  color: var(--primary-color);
  margin-bottom: 1rem;
}

.modal-close {
  position: absolute;
  top: 1rem;
  right: 1rem;
  background-color: var(--primary-color);
  color: #ffffff;
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.modal-close:hover {
  background-color: var(--secondary-color);
}

/* Footer styles */
.App-footer {
  background-color: var(--quaternary-color);
  color: var(--text-color);
  padding: 2rem 0 1rem;
}

.footer-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
}

.social-links a {
  color: var(--secondary-color);
  margin-left: 1rem;
  text-decoration: none;
  transition: color 0.3s ease;
}

.social-links a:hover {
  color: var(--primary-color);
}

.footer-bottom {
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  padding-top: 1rem;
  text-align: center;
}

/* Hamburger menu styles */
.nav-toggle {
  display: none;
  background: none;
  border: none;
  cursor: pointer;
  padding: 0.5rem;
}

.hamburger {
  display: block;
  width: 25px;
  height: 3px;
  background-color: var(--primary-color);
  position: relative;
  transition: background-color 0.3s ease;
}

.hamburger::before,
.hamburger::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 3px;
  background-color: var(--primary-color);
  transition: transform 0.3s ease;
}

.hamburger::before {
  top: -8px;
}

.hamburger::after {
  bottom: -8px;
}

/* Twitter and LinkedIn follow button styles */
.twitter-follow-button,
.linkedin-follow-button {
  display: inline-block;
  background-color: var(--primary-color);
  color: var(--bg-color);
  text-decoration: none;
  padding: 0.5rem 1rem;
  font-size: 0.9rem;
  font-weight: bold;
  border-radius: 5px;
  transition: background-color 0.3s ease;
  margin-top: 1rem;
}

.twitter-follow-button:hover,
.linkedin-follow-button:hover {
  background-color: var(--secondary-color);
}

/* Organisers page styles */
.organisers-page {
  background-color: var(--bg-color);
}

.team-intro {
  background-color: var(--quaternary-color);
  padding: 4rem 0;
}

.team-content {
  display: flex;
  gap: 2rem;
  align-items: center;
}

.team-image {
  flex: 1;
}

.team-image img {
  max-width: 100%;
  border-radius: 10px;
  border: 2px solid var(--primary-color);
}

.team-text {
  flex: 1;
}

.organisers-section {
  padding: 4rem 0;
}

.organisers-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 2rem;
}

.organiser-card {
  background-color: var(--card-bg);
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}

.organiser-card:hover {
  transform: translateY(-5px);
}

.organiser-image {
  width: 100%;
  height: 300px;
  object-fit: cover;
}

.organiser-info {
  padding: 1.5rem;
}

.organiser-info h3 {
  color: var(--primary-color);
  margin-bottom: 0.5rem;
}

.organiser-info p {
  margin-bottom: 1rem;
}

/* Home page upcoming events spacing fix */
.events-section {
  margin-top: 4rem;
}

.events-section .event-card {
  margin-bottom: 2rem;
}

/* Responsive styles */
@media (max-width: 768px) {
  .App-nav ul li {
    margin: 0;
    text-align: center;
  }

  .App-nav ul li a {
    display: block;
    padding: 0.5rem;
  }

  .nav-toggle {
    display: block;
  }

  .nav-toggle.active .hamburger {
    background-color: transparent;
  }

  .nav-toggle.active .hamburger::before {
    transform: translateY(8px) rotate(45deg);
  }

  .nav-toggle.active .hamburger::after {
    transform: translateY(-8px) rotate(-45deg);
  }

  .about-content {
    flex-direction: column;
  }

  .footer-content {
    flex-direction: column;
    gap: 1rem;
  }

  .hero-cta {
    flex-direction: column;
    align-items: center;
  }

  .cta-button {
    /* width: 100%; */
    margin-bottom: 1rem;
  }

  .events-grid {
    grid-template-columns: 1fr;
  }

  .event-card {
    flex-direction: column;
  }

  .event-date {
    flex-direction: row;
    justify-content: center;
    padding: 0.5rem;
  }

  .event-date .day,
  .event-date .month {
    margin: 0 0.5rem;
  }

  .team-content {
    flex-direction: column;
  }

  .organisers-grid {
    grid-template-columns: 1fr;
  }
}

.join-form-container iframe {
  width: 480px; /* Maximum width for larger screens */
  height: 1850px; /* Set a fixed height */
  border: 0; /* Removes default border */
}

@media screen and (max-width: 768px) {
  .join-form-container iframe {
    max-width: 100%;
    height: 2000px;
  }
}

/* Additional styles for consistency and improvements */

/* Improve spacing for sections */
.section {
  margin-bottom: 4rem;
}

/* Enhance text readability */
p {
  font-size: 1rem;
  line-height: 1.6;
  color: var(--text-color);
}

/* Consistent button styling */
.button {
  display: inline-block;
  background-color: var(--primary-color);
  color: var(--bg-color);
  text-decoration: none;
  padding: 0.75rem 1.5rem;
  font-size: 1rem;
  font-weight: bold;
  border-radius: 5px;
  transition: all 0.3s ease;
  border: none;
  cursor: pointer;
}

.button:hover {
  background-color: var(--secondary-color);
}

.button.secondary {
  background-color: transparent;
  border: 2px solid var(--primary-color);
  color: var(--primary-color);
}

.button.secondary:hover {
  background-color: var(--primary-color);
  color: var(--bg-color);
}

/* Improve form styling */
form {
  max-width: 600px;
  margin: 0 auto;
}

form input,
form textarea {
  width: 100%;
  padding: 0.5rem;
  margin-bottom: 1rem;
  box-sizing: border-box;
  border: 1px solid var(--secondary-color);
  border-radius: 5px;
}

form button {
  width: 100%;
}

/* Enhance accessibility */
a:focus,
button:focus,
input:focus,
textarea:focus {
  outline: 2px solid var(--primary-color);
  outline-offset: 2px;
}

/* Improve table styling if used */
table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 1rem;
}

th,
td {
  border: 1px solid var(--secondary-color);
  padding: 0.5rem;
  text-align: left;
}

th {
  background-color: var(--quaternary-color);
  font-weight: bold;
}

/* Add smooth scrolling for better UX */
html {
  scroll-behavior: smooth;
}

/* Improve list styling */
ul,
ol {
  padding-left: 1.5rem;
}

/* Add a subtle hover effect to cards */
.card {
  transition: box-shadow 0.3s ease, transform 0.3s ease;
}

.card:hover {
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
  transform: translateY(-5px);
}

/* Ensure images are responsive */
img {
  max-width: 100%;
  height: auto;
}

/* Add styles for code blocks if needed */
pre,
code {
  background-color: #f4f4f4;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-family: "Courier New", Courier, monospace;
  font-size: 0.9em;
  padding: 0.2em 0.4em;
}

pre {
  padding: 1em;
  overflow-x: auto;
}

/* Style blockquotes */
blockquote {
  border-left: 4px solid var(--primary-color);
  margin: 0;
  padding-left: 1rem;
  font-style: italic;
  color: var(--secondary-color);
}

/* Add styles for custom scrollbar */
::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background: var(--bg-color);
}

::-webkit-scrollbar-thumb {
  background: var(--secondary-color);
  border-radius: 5px;
}

::-webkit-scrollbar-thumb:hover {
  background: var(--primary-color);
}

/* Join Us page styles */
.join-us-page {
  background-color: var(--bg-color);
}

.join-us-intro {
  padding: 4rem 0;
}

.join-us-content {
  display: flex;
  gap: 2rem;
  align-items: flex-start;
}

.join-us-text {
  flex: 1;
}

.join-us-form {
  flex: 1;
}

.join-us-description {
  font-size: 1.1rem;
  line-height: 1.6;
  color: var(--secondary-color);
  margin-bottom: 2rem;
}

.join-us-image {
  max-width: 100%;
  border-radius: 10px;
  margin-bottom: 2rem;
}

.social-follow {
  margin-top: 2rem;
  text-align: center;
}

/* Form styles */
.form-group {
  margin-bottom: 1.5rem;
}

.form-group label {
  display: block;
  margin-bottom: 0.5rem;
  font-weight: bold;
}

.form-group input[type="text"],
.form-group input[type="email"],
.form-group select {
  width: 100%;
  padding: 0.5rem;
  box-sizing: border-box;
  border: 1px solid var(--secondary-color);
  border-radius: 4px;
  font-size: 1rem;
}

.checkbox-group {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
}

.checkbox-group label {
  display: flex;
  align-items: center;
  font-weight: normal;
}

.checkbox-group input[type="checkbox"] {
  margin-right: 0.5rem;
}

/* Twitter follow button */
.twitter-follow-button {
  display: inline-block;
  background-color: #1da1f2;
  color: white;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  text-decoration: none;
  font-weight: bold;
  transition: background-color 0.3s ease;
}

.twitter-follow-button:hover {
  background-color: #0c85d0;
}

/* Responsive styles for Join Us page */
@media (max-width: 768px) {
  .join-us-content {
    flex-direction: column;
  }

  .join-us-text,
  .join-us-form {
    width: 100%;
  }

  .checkbox-group {
    flex-direction: column;
  }

  .checkbox-group label {
    margin-bottom: 0.5rem;
  }

  .social-follow {
    margin-top: 2rem;
  }

  .twitter-follow-button {
    display: block;
    text-align: center;
    margin: 0 auto;
    max-width: 200px;
  }
}
/* Contact Us page styles */
.contact-us-page {
  background-color: var(--bg-color);
}

.contact-us-intro {
  padding: 4rem 0;
}

.contact-us-content {
  display: flex;
  gap: 2rem;
  align-items: flex-start;
}

.contact-us-text {
  flex: 1;
}

.contact-us-form {
  flex: 1;
}

.contact-us-image {
  max-width: 100%;
  border-radius: 10px;
  margin: 2rem 0;
}

.contact-us-text h3 {
  color: var(--primary-color);
  margin-top: 2rem;
  margin-bottom: 1rem;
}

.contact-us-text p {
  margin-bottom: 1rem;
}

.contact-us-text a {
  color: var(--primary-color);
  text-decoration: none;
  font-weight: bold;
}

.contact-us-text a:hover {
  text-decoration: underline;
}

/* Form styles (if not already defined) */
.form-group {
  margin-bottom: 1.5rem;
}

.form-group label {
  display: block;
  margin-bottom: 0.5rem;
  font-weight: bold;
}

.form-group input[type="text"],
.form-group input[type="email"],
.form-group textarea {
  width: 100%;
  padding: 0.5rem;
  box-sizing: border-box;
  border: 1px solid var(--secondary-color);
  border-radius: 4px;
  font-size: 1rem;
}

/* Responsive styles for Contact Us page */
@media (max-width: 768px) {
  .contact-us-content {
    flex-direction: column;
  }

  .contact-us-text,
  .contact-us-form {
    width: 100%;
  }

  .social-follow {
    margin-top: 2rem;
  }

  .twitter-follow-button {
    display: block;
    text-align: center;
    margin: 0 auto;
    max-width: 200px;
  }
}
